import * as React from 'react';
import BodyCopyDivider from '~/components/BodyCopyDivider/BodyCopyDivider.jsx';
import ResourceList from '~/components/ResourceList/ResourceList.jsx';
import AccordionModule from '~/components/AccordionModule/AccordionModule.jsx';
import QuestionList from '~/components/QuestionList/QuestionList.jsx';
import SectionHeader from "~/components/SectionHeader/SectionHeader.jsx";

const ModuleList = ({ modules }) => {

  const moduleList = modules.map((module, index) => {
    switch(module.type) {
      case 'bodyCopyDivider':
        return <BodyCopyDivider key={`module-${index}`} copy={module.sectionHeaderDescription} heading={module.sectionHeaderTitle} />;
      case 'resourceListSection':
        return <ResourceList key={`module-${index}`} heading={module.sectionHeading} resources={module.resources} />;
      case 'accordion':
        const entries = module.entries || module.concepts;
        return <AccordionModule key={`module-${index}`} entries={entries} />;
      case 'questionList':
        return <QuestionList key={`module-${index}`} questions={module.questions} />;
      case 'sectionHeader':
        return <SectionHeader key={`module-${index}`} eyebrow={module.eyebrow} headline={module.headline} />;
      default:
        return null;
    }
  });

  return (
    <section className="ModuleList">
      {moduleList}
    </section>
  );
}
export default ModuleList;
